function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',[(!_vm.withoutTitle)?_c('h4',[_vm._v(" Filters ")]):_vm._e(),_c('b-row',_vm._l((_vm.filtersList),function(ref,idx){
var key = ref.key;
var placeholder = ref.placeholder;
var type = ref.type;
var component = ref.component;
var rest$1 = objectWithoutProperties( ref, ["key", "placeholder", "type", "component"] );
var rest = rest$1;
return _c('b-col',{key:idx,attrs:{"cols":"12","md":_vm.filterInputWidth}},[_c(component,_vm._b({tag:"component",attrs:{"id":key,"placeholder":placeholder,"name":key,"type":type,"clearable":true,"autocomplete":"off"},model:{value:(_vm.innerValue[key]),callback:function ($$v) {_vm.$set(_vm.innerValue, key, $$v)},expression:"innerValue[key]"}},'component',rest,false))],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }