<template>
  <b-form>
    <h4 v-if="!withoutTitle">
      Filters
    </h4>
    <b-row>
      <b-col
        v-for="({ key, placeholder, type, component, ...rest }, idx) in filtersList"
        :key="idx"
        cols="12"
        :md="filterInputWidth"
      >
        <component
          :is="component"
          :id="key"
          v-bind="rest"
          v-model="innerValue[key]"
          :placeholder="placeholder"
          :name="key"
          :type="type"
          :clearable="true"
          autocomplete="off"
        />
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { BCol, BFormGroup, BForm, BRow } from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'

export default {
  name: 'VxTableFilters',
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup
  },
  props: {
    filters: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    withoutTitle: Boolean,
    filterInputWidth: {
      type: Number,
      default: 3
    }
  },

  emits: ['input'],

  setup (props) {
    const filters = ref(props.filters)

    watch(() => props.filters, () => {
      filters.value = props.filters
    })

    return {
      innerValue: props.value,
      filtersList: filters
    }
  }
}
</script>
